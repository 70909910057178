.calendar-time-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.calendar-container {
  flex: 1;
  max-width: 600px;
  flex-direction: column;
}

.time-picker {
  width: 130px;
  margin-left: 0px;
  margin-top: 70px;
  flex-shrink: 0;
  background-color: transparent;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 390px;
  padding: 2px;
  box-sizing: border-box;
}

.time-label {
  margin-top: -50px;
  font-size: large;
  margin-left: 0px;
  position: absolute;
  margin-right: 10px;
  font-weight: 500;
}

.time-slot {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin: 5px 0px 0px 0px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
}

.time-slot:hover {
  background-color: #e0e0e0;
}

.time-slot.selected {
  background-color: #adc7ae;
  color: #3c5c54;
  font-weight: bold;
}

.calendar {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  overflow: hidden;
  width: 95%;
}

.calendar-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.header {
  background-color: #f9f9f9;
  color: #555;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.header .icon {
  cursor: pointer;
  font-size: 25px;
  color: #888;
}

.days {
  background-color: #f0f0f0;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.days .col {
  width: 14.28%;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
  color: #555;
}

.body {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.body .row {
  width: 100%;
  display: flex;
}

.cell {
  width: 14.28%;
  height: 60px;
  background-color: #fff;
  margin: 0.5px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cell:not(.disabled):hover {
  background-color: #f0f0f0;
}

.cell.disabled {
  color: #ccc;
  pointer-events: none;
}

.cell.selected {
  background-color: #adc7ae;
  color: #3c5c54;
  font-weight: bold;
}

.cell .number {
  position: absolute;
  top: 8px;
  right: 8px;
}

.cell .bg {
  font-size: 24px;
  opacity: 0;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  color: #f44336;
}

@media (max-width: 1024px) {
  .time-label {
    margin-top: -40px;
    font-size: large;
    margin-left: 0px;
    position: absolute;
    margin-right: 10px;
    font-weight: 500;
  }
  .calendar-time-container {
    flex-direction: column;
    align-items: center;
  }

  .time-picker {
    width: 90%;
    margin-left: 0;
    margin-top: 60px;
  }

  .calendar-container {
    width: 90%;
    max-width: none;
  }

  .cell {
    height: 60px;
  }
  .header {
    background-color: #f9f9f9;
    color: #555;
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }
}
