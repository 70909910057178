.admin-calendar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
}

.calendar-admin {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: transparent;
    border-radius: 10px;
    padding: 6px;
    margin: 20px 0px 0 100px;
    overflow: hidden;
    width: 50%;
}

.header-admin {
    background-color: transparent;
    color: #555;
    font-size: 20px;
    padding: 15px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.header-admin .icon-admin {
    cursor: pointer;
    font-size: 24px;
    color: #888;
}

.days-admin {
    background-color: transparent;
    display: flex;
}

.days-admin .col-admin {
    width: 14.28%;
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
    color: #555;
}

.body-admin {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.body-admin .row-admin {
    width: 100%;
    display: flex;
}

.cell-admin {
    width: 14.28%;
    height: 80px;
    background-color: rgb(238, 238, 238);
    margin: 6px;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot-admin {
    height: 5px;
    width: 5px;
    background-color: #555;
    border-radius: 50%;
    display: inline-block;
    margin: 3px;
}

.dots-container {
    flex-wrap: wrap;
    height: 50px;
    margin-top: 15px;
    max-width: 75px;
}



.cell-admin.disabled {
    color: #ccc;
    pointer-events: none;
}

.cell-admin.selected {
    background-color: #adc7ae;
    color: #3c5c54;
    font-weight: bold;
}

.cell-admin .number-admin {
    position: absolute;
    top: 8px;
    right: 8px;
}

.cell-admin .bg-admin {
    font-size: 24px;
    opacity: 0;
}

.cell-admin:hover {
    background-color: #adc7ae;
}

.loading-admin,
.error-admin {
    text-align: center;
    padding: 20px;
    color: #f44336;
}

.ride-link {
    text-decoration: none;
    color: #7d7c7c;
}

/* ReservedRidesComponent.css */
.reserved-rides {
    font-family: 'Arial', sans-serif;
    color: #333;
    border-radius: 8px;
    padding: 20px;
    margin: 100px 100px 0px 0px;
    width: 31%;
    height: 65vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.reserved-rides h2 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: -70px;
    position: absolute;
}

.reserved-rides h3 {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: -35px;
    position: absolute;
}

.ride-section h3 {
    font-size: 20px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-top: 20px;
}

.ride {
    background-color: #f9f9f9;
    border: 1px solid #cccaca;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ride:hover {
    background-color: #cddacd; 
}

.ride:not(:last-child) {
    margin-bottom: 15px;
}

.rider-name {
    width: 40%;
    font-size: 15px;
}

.rider-time {
    width: 20%;
    font-size: 15px;
}

.rider-payrate {
    width: 20%;
    font-size: 15px;
}

.ride div {
    margin: 0 10px;
    font-weight: 600;
    
}

.statusInList {
    display: block;
    background-color: #3c5c54ae;
    color: #eae7e7;
    padding: 5px 10px;
    border-radius: 12px;
    text-transform: uppercase;
    width: 18%;
    font-size: 11px;
}

/*ride details*/
.ride-details-container {
    padding: 0px;
}

.ride-details-card {
    background-color: #ECECEC;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
}

.ride-details-profile {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile-image-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
}

.ride-details-body .ride-detail {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ride-details-footer {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.profile-name-status h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.status {
    display: block;
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
    color: green;
}

.ride-details-body {
    padding: 20px;
}

.ride-detail {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.ride-detail i {
    margin-right: 8px;
}

.ride-detail a {
    color: #3c5c54;
    text-decoration: none;
    font-weight: bold;
}

.verified {
    color: green;
}

.email-icon:before {
    content: '\2709'; /* Envelope symbol */
}

.phone-icon:before {
    content: '\260E'; /* Telephone symbol */
}

.location-icon:before {
    content: '\1F4CD'; /* Pushpin symbol */
}

.clock-icon:before {
    content: '\23F0'; /* Alarm clock symbol */
}

.ride-details-footer {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #eaeaea;
}

.cancel-button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: darkred;
}

@media (max-width: 768px) {
    .admin-calendar-container {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .dot-admin {
        height: 5px;
        width: 5px;
        background-color: #555;
        border-radius: 50%;
        margin: 1.5px;
    }

    .dots-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 25px;
        height: 10px;
        margin-top: 15px;
    }

    .cell-admin {
        width: 14.28%;
        height: 55px;
        background-color: rgb(238, 238, 238);
        margin: 5px;
        display: flex;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .cell-admin.selected {
        background-color: #adc7ae;
        color: #3c5c54;
        font-weight: bold;
    }

    .days-admin .col-admin {
        width: 14.28%;
        text-align: center;
        padding: 10px 0;
        font-weight: bold;
        color: #555;
    }

    .header-admin {
        color: #555;
        padding: 10px 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .calendar-admin {
        width: 90%;
        margin: 0px;
    }

    .reserved-rides {
        width: 90%;
        margin: 20px;
        margin-top: 30px;
        height: auto;
    }

    .reserved-rides h2 {
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: -35px;
        position: absolute;
    }

    .ride div {
        margin: 0 10px;
        font-weight: 600;
        font-size: 13px;
    }

    .rider-name {
        width: 25%;
    }
    
    .rider-time {
        width: 10%;
    }
    
    .rider-payrate {
        width: 20%;
    }
    
    .ride div {
        margin: 0 10px;
        font-weight: 600;
        font-size: 15px;
    }
        
    .statusInList {
        display: block;
        background-color: #3c5c54ae;
        color: #eae7e7;
        padding: 5px 10px;
        border-radius: 12px;
        font-size: 10px;
        text-transform: uppercase;
        width: 26%;
    }
}
