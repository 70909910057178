.about-us-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.about-us-container h2 {
    
}

.about-us-container .image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.about-us-container .image-container img {
    width: 40%;
    margin: 10px;
    flex-shrink: 0; 
    cursor: pointer;
}


.driver-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.driver-photo img {
    height: 75px;
    margin: 0 0 20px 0;
    width: 80px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.driver-info {
    text-align: center;
}

.driver-info h2 {
    margin: 0 0 10px 0;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
}

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.pdf-download-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pdf-download-container a button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: black;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

@media (max-width: 768px) {

    .about-us-container .image-container img {
        width: 100%;
    }

    .driver-section {
        flex-direction: column;
    }

    .driver-photo {
        margin: 0 0 20px 0;
        width: 80px;
    }

    .driver-info {
        text-align: center;
    }
}